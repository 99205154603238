import {
    AURRETIK_CONTAINER,
    CATEGORIES_CONTAINER,
    HARTUZ_BOOKING_CONTAINER,
    HARTUZ_CANCELATION_CONTAINER,
    HARTUZ_CONTAINER,
    INCIDENCE_CONTAINER,
    MAPS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    PAGE_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
} from '../constants/containersId'

const PAGES_WITH_BREADCRUMBS = [
    AURRETIK_CONTAINER,
    CATEGORIES_CONTAINER,
    HARTUZ_BOOKING_CONTAINER,
    HARTUZ_CANCELATION_CONTAINER,
    HARTUZ_CONTAINER,
    INCIDENCE_CONTAINER,
    MAPS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    PAGE_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
]

const checkShouldShowBreadcrumbsByPageId = id => PAGES_WITH_BREADCRUMBS.includes(id)

export default checkShouldShowBreadcrumbsByPageId
