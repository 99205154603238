import {
    PAGE_CONTAINER,
    ACTION_CONTAINER,
    CATEGORIES_CONTAINER,
    INCIDENCE_CONTAINER,
    INDICATORS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    NEWS_CONTAINER,
    PLAN_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
    FINDER_CONTAINER,
    AURRETIK_CONTAINER,
    IRUDIZ_CONTAINER,
    HARTUZ_CONTAINER,
} from '../../../constants/containersId'

const selectPageDataById = ({ data, containerId }) => {
    switch (containerId) {
        case ACTION_CONTAINER:
            return data.actions.data
        case CATEGORIES_CONTAINER:
            return data.categories.data[0]
        case INCIDENCE_CONTAINER:
            return data.incidencias.data
        case INDICATORS_CONTAINER:
            return data.indicators.data
        case NEW_INCIDENCE_CONTAINER:
            return data.incidencias.data
        case NEWS_CONTAINER:
            return data.news.data
        case PLAN_CONTAINER:
        case PLAN_PROGRESS_CONTAINER:
        case PLAN_SUMMARY_CONTAINER:
            return data.plans.data
        case FINDER_CONTAINER:
            return data.plans.data[0]
        case PAGE_CONTAINER:
        case AURRETIK_CONTAINER:
        case IRUDIZ_CONTAINER:
        case HARTUZ_CONTAINER:
        default:
            return data.pages.data[0]
    }
}

export default selectPageDataById