import mapsPageSlugs from './slugs'

export const PAGE = '/:lang/:page'
export const AURRETIK = '/:lang/aurretik/:instanceID'
export const AURRETIK_FACILITY = '/:lang/aurretik/:instanceID/:facilityID'
export const INCIDENCES_BY_ID = '/:lang/incidencias/:urlID'
export const PAGES_BY_SLUG = '/:lang/:page/:slug'

export const INCIDENCES_NEW = {
    es: '/:lang/kalezaindu/nueva-incidencia',
    eu: '/:lang/kalezaindu/intzidentzia-berria',
}

export const PLANS_SEARCH = {
    es: '/:lang/planes-de-legislatura/buscador',
    eu: '/:lang/legealdiko-planak/bilatzaile',
}

export const PLANS_BY_SLUG_PROGRESS = {
    es: '/:lang/planes/:slug/progreso',
    eu: '/:lang/planak/:slug/aurrerapena',
}

export const PLANS_BY_SLUG_SUMMARY = '/:lang/planes/:slug/resumen'

export const PLANS_BY_SLUG = {
    es: '/:lang/planes/:slug',
    eu: '/:lang/planak/:slug',
}

export const ACTIONS_BY_SLUG = {
    es: '/:lang/acciones/:slug',
    eu: '/:lang/ekintzak/:slug',
}

export const PAGE_INDICATORS = {
    es: '/:lang/:page/indicadores/:urlID',
    eu: '/:lang/:page/adierazleak/:urlID',
}

export const PAGE_CATEGORIES = {
    es: '/:lang/:page/categorias/:urlID',
    eu: '/:lang/:page/kategoriak/:urlID',
}

export const IRUDIZ_POSTALS = {
    es: '/:lang/irudiz/:instanceID',
    eu: '/:lang/irudiz/:instanceID',
}

export const MAPS = {
    es: `/:lang/${mapsPageSlugs.es}`,
    eu: `/:lang/${mapsPageSlugs.eu}`,
}

export const HARTUZ = {
    es: `/:lang/hartuz`,
    eu: `/:lang/hartuz`,
}

export const HARTUZ_CANCELATION = {
    es: `/:lang/hartuz/cancel`,
    eu: `/:lang/hartuz/cancel`,
}

export const HARTUZ_BOOKING = {
    es: `/:lang/hartuz/booking/:facilityID/:date?`,
    eu: `/:lang/hartuz/booking/:facilityID/:date?`,
}
