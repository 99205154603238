import moment from 'moment'
import React from 'react'
import InternalLink from '../../../atoms/InternalLink/InternalLink'
import { DATE_FORMAT } from '../../../constants'
import t from '../../../i18n/translations/translations'
import './HartuzFacilityCard.css'

const createUrl = (lang, card, date) => {
    const urlDate = date ? moment(date).format(DATE_FORMAT) : null
    return urlDate
        ? `/${lang}/hartuz/booking/${card.id}/${urlDate}`
        : `/${lang}/hartuz/booking/${card.id}`
}

function HartuzFacilityCard({ card, lang, selectedDate }) {
    return (
        <article className="hartuzFacilityCard">
            <div className="hartuzFacilityCard__top">
                <h3>{card.name[lang]}</h3>
            </div>
            <div className="hartuzFacilityCard__bottom">
                <InternalLink url={createUrl(lang, card, selectedDate)}>
                    {t.make_booking[lang]}
                </InternalLink>
            </div>
        </article>
    )
}

export default HartuzFacilityCard
