import { withFormik } from 'formik'
import React from 'react'
import { bool, object, string } from 'yup'
import Button from '../../atoms/Button/Button'
import FormCheckbox from '../../atoms/FormCheckBox/FormCheckBox'
import FormError from '../../atoms/FormError/FormError'
import FormGroup from '../../atoms/FormGroup/FormGroup'
import FormGroupInput from '../../atoms/FormGroupInput/FormGroupInput'
import {
    EMAIL_REGEX,
    ONLY_LETTERS_AND_NUMBERS_REGEX
} from '../../constants/regex'
import STATES from '../../constants/states'
import { validateDNI } from '../../constants/validateDNI'
import t from '../../i18n/translations/translations'
import AcceptPolicyLabel from '../AcceptPolicyLabel'
import './HartuzCancelationForm.css'

const HartuzCancelationForm = withFormik({
    displayName: 'HartuzCancelationForm',
    handleSubmit: (values, formikBag) => {
        if (typeof formikBag.props.handleSubmit !== 'function') {
            return
        }
        formikBag.props.handleSubmit(values)
    },
    mapPropsToValues: (props) => ({
        dni: '',
        email: '',
        booking_code: '',
        accept_policy: false,
        instanceID: props.instanceID,
        lang: props.lang,
    }),

    validationSchema: (props) => {
        const { lang } = props
        return object().shape({
            dni: string()
                .test('dni', t.error_valid_id[lang], (value) => {
                    if (value) {
                        const result = validateDNI(value)
                        if (result.valid) {
                            return true
                        }
                    }
                    return false
                })
                .required(t.error_required_id[lang]),
            booking_code: string()
                .matches(
                    ONLY_LETTERS_AND_NUMBERS_REGEX,
                    t.error_booking_code[lang]
                )
                .required(t.error_required_booking_code[lang]),
            email: string()
                .matches(EMAIL_REGEX, t.error_email[lang])
                .required(t.error_required_email[lang]),
            accept_policy: bool().oneOf([true], t.error_accept_policy[lang]),
        })
    },
})(
    ({
        errors,
        touched,
        handleChange,
        handleSubmit,
        values,
        lang,
        state,
        setFieldValue,
    }) => (
        <form onSubmit={handleSubmit}>
            <div className="hartuzCancellationform">
                <div className="hartuzCancellationform__input--wrapper">
                    <FormGroupInput
                        autoComplete="dni"
                        errors={touched.dni && errors.dni ? errors.dni : null}
                        aria-invalid={
                            touched.dni && errors.dni ? errors.dni : null
                        }
                        id="dni"
                        label={t.dni[lang]}
                        onChange={handleChange}
                        placeholder={t.dni[lang]}
                        required
                        value={values.dni}
                    />
                </div>

                <div className="hartuzCancellationform__input--wrapper">
                    <FormGroupInput
                        autoComplete="email"
                        errors={
                            touched.email && errors.email ? errors.email : null
                        }
                        aria-invalid={
                            touched.email && errors.email ? errors.email : null
                        }
                        id="email"
                        label={t.email[lang]}
                        onChange={handleChange}
                        placeholder={t.email[lang]}
                        required
                        type="email"
                        value={values.email}
                    />
                </div>
                <div className="hartuzCancellationform__input--wrapper">
                    <FormGroupInput
                        errors={
                            touched.booking_code && errors.booking_code
                                ? errors.booking_code
                                : null
                        }
                        aria-invalid={
                            touched.booking_code && errors.booking_code
                                ? errors.booking_code
                                : null
                        }
                        id="booking_code"
                        label={t.booking_code[lang]}
                        onChange={handleChange}
                        required
                        value={values.booking_code}
                        placeholder={t.booking[lang]}
                    />
                </div>

                <FormGroup
                    className="hartuzCancellationform__checkbox"
                    errors={
                        touched.accept_policy && errors.accept_policy
                            ? errors.accept_policy
                            : null
                    }
                    id="accept_policy"
                    label=""
                    required
                >
                    <FormCheckbox
                        errors={
                            touched.accept_policy && errors.accept_policy
                                ? errors.accept_policy
                                : null
                        }
                        aria-invalid={
                            touched.accept_policy && errors.accept_policy
                                ? errors.accept_policy
                                : null
                        }
                        label={AcceptPolicyLabel(lang)}
                        name="politicsButtonCheck"
                        onChange={() =>
                            setFieldValue(
                                'accept_policy',
                                !values.accept_policy
                            )
                        }
                        value={values.accept_policy}
                    />
                </FormGroup>
                <div className="hartuzCancellationform__submit">
                    <Button
                        disabled={state === STATES.SUBMITTING}
                        type="submit"
                    >
                        {t.action_cancel_booking[lang]}
                    </Button>
                </div>
            </div>
            <div className="hartuzCancellationform__errors">
                {state === STATES.ERROR && (
                    <FormError className="form-group__errors">
                        {t.error_cancelation_no_booking[lang]}
                    </FormError>
                )}

                {state === STATES.ERROR_TIME_EXPIRED && (
                    <FormError className="form-group__errors">
                        {t.error_cancelation_no_booking_time[lang]}
                    </FormError>
                )}
            </div>
        </form>
    )
)

export default HartuzCancelationForm
