import { APIProvider } from '@vis.gl/react-google-maps'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import {
    ACTION_CONTAINER,
    AURRETIK_CONTAINER,
    CATEGORIES_CONTAINER,
    FINDER_CONTAINER,
    HARTUZ_BOOKING_CONTAINER,
    HARTUZ_CANCELATION_CONTAINER,
    HARTUZ_CONTAINER,
    INCIDENCE_CONTAINER,
    INDICATORS_CONTAINER,
    IRUDIZ_CONTAINER,
    MAPS_CONTAINER,
    NEWS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    PAGE_CONTAINER,
    PLAN_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
} from './constants/containersId'
import BaseContainer from './containers/BaseContainer/BaseContainer'
import ErrorBoundary from './helpers/ErrorBoundary'
import ScrollToTop from './helpers/ScrollToTop'
import {
    ACTIONS_BY_SLUG,
    AURRETIK,
    AURRETIK_FACILITY,
    HARTUZ,
    HARTUZ_BOOKING,
    HARTUZ_CANCELATION,
    INCIDENCES_BY_ID,
    INCIDENCES_NEW,
    IRUDIZ_POSTALS,
    MAPS,
    PAGE,
    PAGES_BY_SLUG,
    PAGE_CATEGORIES,
    PAGE_INDICATORS,
    PLANS_BY_SLUG,
    PLANS_BY_SLUG_PROGRESS,
    PLANS_BY_SLUG_SUMMARY,
    PLANS_SEARCH,
} from './routing/routes'
import { setSelectedUA } from './store/actions'
import getLangByLocation from './utils/getLangByLocation'
import getLocaleByLocation from './utils/getLocaleByLocation'

function App({ setSelectedUAStore, data }) {
    const [analyticsInitialized, setAnalyticsInitialized] = useState(false)
    const location = useLocation()
    const lang = location && getLangByLocation(location)
    const locale = getLocaleByLocation(location)

    useEffect(() => {
        document.documentElement.lang = lang
    }, [lang])

    useEffect(() => {
        const page = {
            domain: encodeURIComponent(
                window.location.href.replace(location.pathname, '')
            ),
        }
        setSelectedUAStore(page)

        if (data && !analyticsInitialized) {
            ReactGA.initialize(data.analitycs)
            setAnalyticsInitialized(true)
        }
    }, [analyticsInitialized])

    return (
        <ErrorBoundary>
            <ScrollToTop>
                <APIProvider
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                    language="eu"
                >
                    <div>
                        <Routes>
                            <Route
                                path="/"
                                element={<Navigate to={locale} />}
                            />
                            <Route
                                path={MAPS[lang]}
                                element={
                                    <BaseContainer
                                        containerId={MAPS_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path="/:lang"
                                element={
                                    <BaseContainer
                                        containerId={PAGE_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={IRUDIZ_POSTALS[lang]}
                                element={
                                    <BaseContainer
                                        containerId={IRUDIZ_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={HARTUZ[lang]}
                                element={
                                    <BaseContainer
                                        containerId={HARTUZ_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={HARTUZ_CANCELATION[lang]}
                                element={
                                    <BaseContainer
                                        containerId={
                                            HARTUZ_CANCELATION_CONTAINER
                                        }
                                    />
                                }
                            />
                            <Route
                                path={HARTUZ_BOOKING[lang]}
                                element={
                                    <BaseContainer
                                        containerId={HARTUZ_BOOKING_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PAGE}
                                element={
                                    <BaseContainer
                                        containerId={PAGE_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={AURRETIK}
                                element={
                                    <BaseContainer
                                        containerId={AURRETIK_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={AURRETIK_FACILITY}
                                element={
                                    <BaseContainer
                                        containerId={AURRETIK_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={INCIDENCES_BY_ID}
                                element={
                                    <BaseContainer
                                        containerId={INCIDENCE_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={INCIDENCES_NEW[lang]}
                                element={
                                    <BaseContainer
                                        containerId={NEW_INCIDENCE_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PLANS_SEARCH[lang]}
                                element={
                                    <BaseContainer
                                        containerId={FINDER_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PLANS_BY_SLUG_PROGRESS[lang]}
                                element={
                                    <BaseContainer
                                        containerId={PLAN_PROGRESS_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PLANS_BY_SLUG_SUMMARY}
                                element={
                                    <BaseContainer
                                        containerId={PLAN_SUMMARY_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PLANS_BY_SLUG[lang]}
                                element={
                                    <BaseContainer
                                        containerId={PLAN_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={ACTIONS_BY_SLUG[lang]}
                                element={
                                    <BaseContainer
                                        containerId={ACTION_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PAGES_BY_SLUG}
                                element={
                                    <BaseContainer
                                        containerId={NEWS_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PAGE_INDICATORS[lang]}
                                element={
                                    <BaseContainer
                                        containerId={INDICATORS_CONTAINER}
                                    />
                                }
                            />
                            <Route
                                path={PAGE_CATEGORIES[lang]}
                                element={
                                    <BaseContainer
                                        containerId={CATEGORIES_CONTAINER}
                                    />
                                }
                            />
                        </Routes>
                    </div>
                </APIProvider>
            </ScrollToTop>
        </ErrorBoundary>
    )
}

const mapStateToProps = ({ analyticsUA, analyticsUAs }) => ({
    analyticsUA,
    data: analyticsUAs[analyticsUA.domain],
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedUAStore: (payload) => dispatch(setSelectedUA(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
