const transformFacility = ({ data }) => ({
    available: data.available,
    available_days_message: {
        es: data.booking_date_es,
        eu: data.booking_date_eu,
    },
    booking_slot: data.booking_slot,
    dates: data.dates,
    description: {
        es: data.description_es,
        eu: data.description_eu,
    },
    facility_type: data.id_facility_type,
    email: data.email,
    id: data.id_facility,
    image: data.image ? `hartuz/${data.idinstancia}/${data.image}` : null,
    name: {
        es: data.facility_name_es,
        eu: data.facility_name_eu,
    },
    pvp: data.pvp,
    text_info: {
        es: data.text_info_es,
        eu: data.text_info_eu
    },
    time_to_pay: data.time_to_pay
})

export default transformFacility