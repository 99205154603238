/* eslint-disable func-names */
import classNames from 'classnames'
import {
    MDBCollapse,
    MDBIcon,
    MDBNavbar,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBPopover,
} from 'mdb-react-ui-kit'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CurrentLanguage from '../../atoms/CurrentLanguage/CurrentLanguage'
import HeaderLangButtons from '../../atoms/HeaderLangButtons/HeaderLangButtons'
import HeaderLogo from '../../atoms/HeaderLogo/HeaderLogo'
import { HeaderSearch } from '../../atoms/HeaderSearch/HeaderSearch'
import HeaderToggleButton from '../../atoms/HeaderToggleButton/HeaderToggleButton'
import getImagesRoute from '../../utils/getImagesRoute'
import Macromenu from '../Macromenu/Macromenu'
import './Header.css'
import t from '../../i18n/translations/translations'

function Header({
    logo,
    match,
    collapseSearch,
    trads,
    isWideEnough,
    handleSearchBar,
    menu,
    menuInverted,
    headbackground,
    macromenuCollapsed,
    handleMacromenuCollapse,
    menuItems,
    fromError,
}) {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    const { lang, page } = match
    const imagesRoute = getImagesRoute()
    const backgroundImage =
        headbackground !== null
            ? `${imagesRoute}${headbackground.imagen}`
            : '/img/header.jpg'
    const logoInstancia = logo !== null ? `${imagesRoute}${logo.imagen}` : ''
    const linkHome = `/${lang}`
    const slug = page !== undefined

    const imgLayerClass = classNames({
        imgLayer: true,
        'macromenu--opened': !macromenuCollapsed,
    })

    const navbarWrapperClass = classNames({
        'navbar-wrapper': true,
        'macromenu--opened': !macromenuCollapsed,
    })

    const overWrapperClass = classNames({
        overWrapper: true,
        'macromenu--opened': !macromenuCollapsed,
    })

    const navBar = classNames({
        'macromenu--opened': !macromenuCollapsed,
    })

    const backgroundImg = classNames({
        'background-img': true,
        'macromenu--opened': !macromenuCollapsed,
    })

    return (
        <div className="hero" id="top">
            <div className="layer">
                <header role="banner">
                    <div className={navbarWrapperClass}>
                        <div className={overWrapperClass}>
                            <div className="overMenu">
                                <HeaderSearch
                                    action={handleSearchBar}
                                    collapseSearch={collapseSearch}
                                    lang={lang}
                                    trads={trads}
                                />
                                <div style={{ display: 'flex' }}>
                                    <MDBPopover
                                        aria-label={t.language_menu[lang]}
                                        aria-haspopup
                                        btnChildren={
                                            <CurrentLanguage trads={trads} />
                                        }
                                        btnClassName="toolMenu__button"
                                        dismiss
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        placement="bottom"
                                        style={
                                            isFocused
                                                ? { outline: '2px solid black' }
                                                : {}
                                        }
                                    >
                                        <HeaderLangButtons trads={trads} />
                                    </MDBPopover>
                                </div>
                                <HeaderToggleButton
                                    action={handleMacromenuCollapse}
                                    lang={lang}
                                    macromenuCollapsed={macromenuCollapsed}
                                />
                            </div>
                        </div>
                        <HeaderLogo
                            lang={lang}
                            link={linkHome}
                            logo={logo}
                            logoInstancia={logoInstancia}
                        />
                        <MDBNavbar dark expand="xl" scrolling>
                            {!isWideEnough && (
                                <div className="gradient">
                                    <div className="menuItems">
                                        <HeaderSearch
                                            action={handleSearchBar}
                                            collapseSearch={collapseSearch}
                                            lang={lang}
                                            trads={trads}
                                        />
                                        <div style={{ display: 'flex' }}>
                                            <MDBPopover
                                                btnChildren={
                                                    <CurrentLanguage
                                                        trads={trads}
                                                    />
                                                }
                                                btnClassName="toolMenu__button"
                                                placement="bottom"
                                                tag="button"
                                            >
                                                <HeaderLangButtons
                                                    trads={trads}
                                                />
                                            </MDBPopover>
                                        </div>

                                        <MDBNavbarToggler
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                            className="togglerMenu"
                                            title="navbar-toggler"
                                            onClick={handleMacromenuCollapse}
                                        >
                                            <MDBIcon
                                                icon="bars"
                                                style={{ color: 'white' }}
                                            />
                                        </MDBNavbarToggler>
                                    </div>
                                </div>
                            )}
                            <MDBCollapse
                                open={false}
                                navbar
                                className={navBar}
                                style={{ height: 'auto' }}
                            >
                                <MDBNavbarNav left>
                                    {menu?.length &&
                                        menu.map((item, index) => {
                                            const rowLen = menu.length
                                            const linkClass = classNames({
                                                'menu-inverted':
                                                    menuInverted !== '0',
                                                'menu-normal':
                                                    menuInverted !== '1',
                                                'last-link':
                                                    rowLen === index + 1,
                                                'not-active':
                                                    (index === 0 &&
                                                        slug === true) ||
                                                    fromError,
                                                'macromenu--opened':
                                                    !macromenuCollapsed,
                                            })

                                            return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <MDBNavbarItem key={index}>
                                                    <MDBNavbarLink
                                                        className={linkClass}
                                                        href={item.slug}
                                                    >
                                                        {item.titulo}
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                            )
                                        }, this)}
                                </MDBNavbarNav>
                            </MDBCollapse>
                        </MDBNavbar>
                    </div>
                </header>
            </div>
            <div className={imgLayerClass} role="banner">
                <img src={backgroundImage} alt="" className={backgroundImg} />
            </div>
            <Macromenu
                handleMacromenuCollapse={handleMacromenuCollapse}
                macromenuCollapsed={macromenuCollapsed}
                menuItems={menuItems}
            />
        </div>
    )
}

Header.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ).isRequired,
    logo: PropTypes.shape({
        idimagen: PropTypes.number.isRequired,
        imagen: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
        lang: PropTypes.string.isRequired,
    }).isRequired,
    trads: PropTypes.shape({
        lang: PropTypes.string.isRequired,
        buscar: PropTypes.string.isRequired,
        castellano: PropTypes.string.isRequired,
        idioma: PropTypes.string.isRequired,
    }).isRequired,
    headbackground: PropTypes.shape({
        idimagen: PropTypes.number.isRequired,
        imagen: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
    }).isRequired,
    collapseSearch: PropTypes.bool.isRequired,
    isWideEnough: PropTypes.bool.isRequired,
    handleSearchBar: PropTypes.func.isRequired,
    menuInverted: PropTypes.number,
    macromenuCollapsed: PropTypes.bool.isRequired,
    handleMacromenuCollapse: PropTypes.func.isRequired,
    fromError: PropTypes.bool,
}

Header.defaultProps = {
    menuInverted: 0,
    fromError: false,
}

export default Header
