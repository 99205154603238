import STATES from '../../constants/states'
import {
    SET_HARTUZ,
    SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE,
    SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE_DATA,
    SET_HARTUZ_AVAILABLE_FACILITIES_DATE_TYPE,
    SET_HARTUZ_AVAILABLE_FACILITIES_DATE_TYPE_DATA,
    SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE,
    SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE_DATA,
    SET_HARTUZ_DATA,
    SET_HARTUZ_FACILITY,
    SET_HARTUZ_FACILITY_BOOKING_ERROR,
    SET_HARTUZ_FACILITY_BOOKING_ERROR_TIME_EXPIRED,
    SET_HARTUZ_FACILITY_BOOKING_ERROR_CONCURRENCE,
    SET_HARTUZ_FACILITY_BOOKING_SUBMITTING,
    SET_HARTUZ_FACILITY_BOOKING_SUCCESS,
    SET_HARTUZ_FACILITY_DATA,
    SET_HARTUZ_FACILITY_BOOKING_RESET,
} from '../constants/constants'

const initialState = {
    facilities: [],
    facility: null,
    dates: null,
    hours: null,
    bookingFormState: STATES.DEFAULT,
    booking: null,
    hoursLoading: false,
    datesLoading: false,
    facilitiesLoading: false,
}

// eslint-disable-next-line default-param-last
const hartuz = (state = initialState, action) => {
    switch (action.type) {
        case SET_HARTUZ:
            return {
                ...state,
                hartuz: action.payload,
                facilitiesLoading: true,
                facilities: [],
            }

        case SET_HARTUZ_DATA:
            return {
                ...state,
                hartuz: action.payload,
                facilitiesLoading: false,
                facilities: action.payload.data.data,
            }

        case SET_HARTUZ_FACILITY:
            return {
                ...state,
                facilitiesLoading: true,
                facility: action.payload,
            }

        case SET_HARTUZ_FACILITY_DATA:
            return {
                ...state,
                facilitiesLoading: false,
                facility: action.payload,
            }

        case SET_HARTUZ_AVAILABLE_FACILITIES_DATE_TYPE:
            return {
                ...state,
                facilitiesLoading: true,
                facilitiesByType: [],
            }

        case SET_HARTUZ_AVAILABLE_FACILITIES_DATE_TYPE_DATA:
            return {
                ...state,
                facilitiesLoading: false,
                facilitiesByType: action.payload.data,
            }

        case SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE:
            return {
                ...state,
                datesLoading: true,
                dates: action.payload,
            }

        case SET_HARTUZ_AVAILABLE_DATES_DATE_TYPE_DATA:
            return {
                ...state,
                datesLoading: false,
                dates: action.payload,
            }

        case SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE:
            return {
                ...state,
                hoursLoading: true,
                hours: action.payload,
            }

        case SET_HARTUZ_AVAILABLE_HOURS_DATE_TYPE_DATA:
            return {
                ...state,
                hoursLoading: false,
                hours: action.payload,
            }
        case SET_HARTUZ_FACILITY_BOOKING_RESET:
            return {
                ...state,
                bookingFormState: STATES.DEFAULT,
                booking: action.payload,
            }
        case SET_HARTUZ_FACILITY_BOOKING_SUBMITTING:
            return {
                ...state,
                bookingFormState: STATES.SUBMITTING,
                booking: action.payload,
            }
        case SET_HARTUZ_FACILITY_BOOKING_SUCCESS:
            return {
                ...state,
                bookingFormState: STATES.SUCCESS,
                booking: action.payload,
            }
        case SET_HARTUZ_FACILITY_BOOKING_ERROR:
            return {
                ...state,
                booking: action.payload,
                bookingFormState: STATES.ERROR,
            }
        case SET_HARTUZ_FACILITY_BOOKING_ERROR_TIME_EXPIRED:
            return {
                ...state,
                bookingFormState: STATES.ERROR_TIME_EXPIRED,
                booking: action.payload,
            }
        case SET_HARTUZ_FACILITY_BOOKING_ERROR_CONCURRENCE:
            return {
                ...state,
                bookingFormState: STATES.ERROR_CONCURRENCE,
                booking: action.payload,
            }
        default:
            return state
    }
}

export default hartuz
